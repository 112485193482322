import React from "react";
import styled from "styled-components";
import SocialLinks from "./common/SocialLinks";
import { Link } from "gatsby";
import InstagramImages from "../components/InstagramImages";
import addToMailchimp from "gatsby-plugin-mailchimp";
import AboutSection from "../components/AboutSection";

const initialSubScribeFormData = {
  FNAME: "",
  LNAME: "",
  email: "",
  PATHNAME: "/"
};

const successMsg = `You've been added to the list successfully!`;
const errorMsg =
  "An error occured. You may already have signed up with that email";

const errorState = {
  formSuccess: "",
  formError: errorMsg
};

const successState = {
  formError: "",
  formSuccess: successMsg,
  subscribeFormData: initialSubScribeFormData
};

class Subscription1 extends React.Component {
  state = {
    subscribeFormData: initialSubScribeFormData,
    formError: "",
    formSuccess: ""
  };

  onInputChange = (name, value) => {
    this.setState(({ subscribeFormData }) => ({
      subscribeFormData: {
        ...subscribeFormData,
        [name]: value
      }
    }));
  };

  subscribeUser = async e => {
    e.preventDefault();
    this.setState({
      formSuccess: "",
      formError: ""
    });

    try {
      console.log(this.state.subscribeFormData);
      const { email, FNAME, LNAME, PATHNAME } = this.state.subscribeFormData;

      if (!email && !FNAME) {
        return this.setState(errorState);
      }

      const { result } = await addToMailchimp(email, {
        FNAME,
        LNAME,
        PATHNAME
      });
      console.log("there and back?", result);
      if (result == "success") {
        this.setState(successState);
      } else {
        this.setState(errorState);
      }
    } catch (e) {
      console.log("error: ", e);
      console.log("error: ", e.message);
      this.setState(errorState);
    }
  };

  render() {
    const { siteTitle } = this.props;

    return (
      <Subscription1El>
        <div className="section-container">
          <div className="subscription-container">
            <div className="subcription-headerText-wrapper">
              <h4 className="h4-text">
                Get all the exclusive stuff in your inbox!
              </h4>
            </div>
            <div className="leftText-and-form-wrapper">
              <div className="leftText-wrapper">
                <div className="leftTextBox">
                  <h3 className="entry-content-subtitle">
                    {"MEMBERS ONLY MEET UPS AND EVENTS"}
                  </h3>
                  <p className="paragraph-text">
                    {
                      "Be the first to know about upcoming events, classes & fitness retreats."
                    }
                  </p>
                  <h3 className="entry-content-subtitle">
                    {"FITNESS INSPIRATION ANYWHERE, ANYTIME"}
                  </h3>
                  <p className="paragraph-text">
                    {
                      "Whether you’re a busy babe on-the-go, a super mom, or a hustlin’ student, you’ll always find this space full of inspiration 24/7."
                    }
                  </p>
                </div>
              </div>
              <div className="form-wrapper">
                <form onSubmit={this.subscribeUser}>
                  <input
                    id="fname"
                    type="text"
                    value={this.state.subscribeFormData.FNAME}
                    onChange={e => this.onInputChange("FNAME", e.target.value)}
                    placeholder="First Name"
                  />
                  <input
                    id="lname"
                    type="email"
                    value={this.state.subscribeFormData.email}
                    onChange={e => this.onInputChange("email", e.target.value)}
                    placeholder="Email"
                  />
                  <input type="submit" value="Sign Me Up!" />
                  <div className="small-info-text error">
                    {this.state.formError}
                  </div>
                  <div className="small-info-text success">
                    {this.state.formSuccess}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <h4 className="instagram-title-text">
          Follow @everyfitgirl on Instagram #everyfitgirl
        </h4>
        <InstagramImages limit={6} />

        <div className="sociallinks-container">
          <SocialLinks />
        </div>
        <AboutSection />

        <div className="copyright-container">
          <div className="copyright-text">
            <span>© 2019 Every Fit Girl</span>
          </div>
          <div className="copyright-nav-wrapper">
            <ul>
              <Link to="">
                <li className="nav-text">home</li>
              </Link>
              <Link to="/blogposts/">
                <li className="nav-text">blog</li>
              </Link>

              <Link to="/workouts/">
                <li className="nav-text">Workouts</li>
              </Link>
              <Link to="/about/">
                <li className="nav-text">about</li>
              </Link>

              <Link to="/contact/">
                <li className="nav-text">contact</li>
              </Link>
            </ul>
          </div>
          {/* <div className="copyright-text">
            <span>Designed & built with love by Dreyah</span>
          </div> */}
        </div>
      </Subscription1El>
    );
  }
}

const Subscription1El = styled.div`
  .section-container {
    background: #ffffff;
    text-align: center;
    display: flex;
    padding: 3% 4% 6%;

    .subscription-container {
      width: 100%;
      flex-shrink: 0;

      .h4-text {
        text-transform: uppercase;
        margin-bottom: 1.5rem;
      }

      .leftText-and-form-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 12px solid rgba(0, 0, 0, 0.07);
        padding: 3%;
        .leftText-wrapper {
          text-align: left;

          margin-right: 24px;
          display: flex;
          width: 50%;

          .entry-content-subtitle {
            margin-bottom: 0.3rem;
            font-size: 0.9rem;
            font-family: "Karla";
            opacity: 0.8;
          }
          .paragraph-text {
            font-size: 0.9rem;
            color: #635359;
          }
        }

        .form-wrapper {
          width: 50%;

          form {
            font-family: "Karla";
            margin-bottom: 0px;
            input[type="text"],
            input[type="email"],
            select {
              font-family: "Karla";
              font-size: 0.9rem;
              width: 100%;
              padding: 12px 20px;
              margin: 8px 0;
              display: inline-block;
              border: 1px solid #33333345;
              border-radius: 4px;
              box-sizing: border-box;
            }

            input[type="submit"] {
              font-family: "Karla";
              font-size: 0.9rem;
              color: #eee;
              box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
              background-color: #0e0e0e;
              text-transform: uppercase;
              border-radius: 2px;
              width: 100%;
              padding: 14px 20px;
              margin: 8px 0;
              border: none;
              cursor: pointer;
              transition: all 0.2s ease-out;
            }

            input[type="submit"]:hover {
              background: #fb77adb3;
              box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
              color: #eee;
            }

            .small-info-text {
              font-weight: bold;
              font-size: 14px;

              &.success {
                color: #63e463;
              }

              &.error {
                color: #f14848;
              }
            }
          }
        }
      }
    }
  }

  .instagram-title-text {
    text-transform: uppercase;
    font-family: "Karla";
    font-size: 0.9rem;
    font-weight: 100;
    letter-spacing: -0.3px;
    line-height: 1.1;
    opacity: 0.8;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  .sociallinks-container {
    padding: 2%;
  }

  .copyright-container {
    /* background: #8c81de; */
    background: #0e0e0e;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    color: #fff;
    flex-wrap: wrap;

    .copyright-text {
      color: #ffffff;
      margin: 0;
      text-transform: uppercase;
      font-size: 0.8rem;
      letter-spacing: 0.9px;
      line-height: 38px;
      font-family: "Karla";
      /* padding-right: 10px; */
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        padding: 0 15px;
        color: #ffffff;

        /* &:last-child {
          padding-right: 0px;
        } */

        &:hover {
          color: #8ce9ff;
        }
      }
    }
  }

  @media screen and (max-width: 970px) {
    .leftText-and-form-wrapper {
      flex-wrap: wrap;
    }
    .section-container
      .subscription-container
      .leftText-and-form-wrapper
      .leftText-wrapper {
      text-align: center;
      width: 100%;
    }
    .section-container
      .subscription-container
      .leftText-and-form-wrapper
      .form-wrapper {
      width: 100%;
    }
    .copyright-container {
      .copyright-text {
        padding-right: 0px;
      }
      ul {
        a {
          padding: 0 10px;
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .copyright-container ul a {
      padding: 0 6px;
    }
  }

  /* @media only screen and (max-width: 592px) {
    .section-container .subscription-container .h4-text {
      font-size: 1.6rem;
    }
  } */
`;

export default Subscription1;
