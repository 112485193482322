import React from "react";
import styled from "styled-components";
import SectionDivider from "../SectionDivider";
import InstagramImages from "../InstagramImages";
import Checkbox from "../Checkbox";
import YoutubeFrame from "../common/YoutubeFrame";

const SideBarEl = styled.div`
  .side-bar-container {
    width: 25%;
    min-width: 287px;
    .side-bar-wrapper {
      margin-bottom: 50px;
    }
  }

  /* media queries start here */

  @media screen and (max-width: 1200px) {
    .side-bar-container {
      width: 100%;
    }
  }
`;

const SideBar = () => {
  return (
    <SideBarEl>
      <div className="side-bar-container">
        <div className="side-bar-wrapper">
          <SectionDivider small title="Instagram" />
          <InstagramImages
            instaImageStyle={{
              flex: "0 0 50%",
              border: "2px solid white"
            }}
            limit={4}
            imagesWrapperStyle={{
              flexWrap: "wrap"
            }}
          />
        </div>
        {/* <div className="side-bar-wrapper">
          <SectionDivider small title="podcast" />
          <iframe
            height="200px"
            width="100%"
            frameborder="no"
            scrolling="no"
            seamless
            src="https://player.simplecast.com/6c59edc1-3a56-4790-b68b-69baa67e1fad?dark=true"
          />
        </div> */}
        <div className="side-bar-wrapper">
          <SectionDivider small title="YOUTUBE" />
          <YoutubeFrame />
        </div>
        {/* <div className="side-bar-wrapper">
          <SectionDivider small title="categories" />
          <Checkbox />
        </div> */}
      </div>
    </SideBarEl>
  );
};

export default SideBar;
