import React from "react";

class HeaderWrapper extends React.Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    return this.props.children(
      this.state.sideDrawerOpen,
      this.backdropClickHandler,
      this.drawerToggleClickHandler
    );
  }
}

export default HeaderWrapper;
