import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const ViewMoreButtonEl = styled.div`
  /* padding-bottom: 35px;
  padding-top: 35px; */
  .view-more-button {
    text-align: center;

    p {
      padding: 11px 40px;
      background: #0e0e0e;
      display: inline-block;
      text-decoration: none;
      color: #eee;
      font: 14px Karla;
      cursor: pointer;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
      text-align: center;
      border-radius: 2px;
      transition: all 0.2s ease-in-out;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:hover {
        background: #00a2bf;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        color: #eee;
      }
    }
  }
`;

const ViewMoreButton = ({ title, link, externalLink }) => {
  // so we want to take externalLink and ask, if it is present, render an a tag, if not, render a Link
  return (
    <ViewMoreButtonEl>
      {externalLink ? (
        <a href={externalLink} target="_blank">
          <div className="view-more-button">
            <p>{title}</p>
          </div>
        </a>
      ) : (
        <Link to={link || "/blogposts/"}>
          <div className="view-more-button">
            <p>{title}</p>
          </div>
        </Link>
      )}
    </ViewMoreButtonEl>
  );
};

export default ViewMoreButton;
