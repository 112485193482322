import React from "react";
import styled from "styled-components";

const YoutubeFrameEl = styled.div`
  width: ${({ width }) => {
    return width ? "50%" : "100%";
  }};
  padding: 3%;

  .video-wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
  .video-wrapper iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }

  @media only screen and (max-width: 830px) {
    width: 100%;
    padding: 3%;
  }
`;

const YoutubeFrame = ({ width }) => {
  return (
    <YoutubeFrameEl width={width}>
      <div className="video-wrapper">
        <iframe
          src="https://www.youtube.com/embed/IBjgj3YN2wA"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    </YoutubeFrameEl>
  );
};

export default YoutubeFrame;
