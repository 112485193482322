import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import parse from "html-react-parser";
import styled from "styled-components";
import SideBar from "../components/common/SideBar";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import header from "../components/header2";
import { posix } from "path";

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
      console.log("image node: ", node);
      return `<img src="${node.data.target.fields.file["en-US"].url}" alt="${
        node.data.target.fields.description
          ? node.data.target.fields.description["en-US"]
          : `image`
      }"/>`;
    }
  }
};

const BodyContentEl = styled.article`
  display: flex;
  padding: 4% 15%;
  .blogpost {
    /* padding-right: 5%;
    width: 75%; */
    .date-text {
      color: #ababab;
      font-style: italic;
      font-size: 80%;
    }
    a {
      text-decoration: none;

      .category-pill {
        background: red;
        max-width: max-content;
        padding: 4px 20px;
        border-radius: 4px;
        background: #00a5cbd4;
        color: white;
        transition: all 0.2s ease-in-out;

        &:hover {
          background: #0788a6d4;
        }
      }
    }
  }

  .blog-nav-btn {
    margin-right: 16px;
    text-decoration: none;
    font-weight: bold;
    color: #00a5cbd4;

    &:hover {
      color: #06677dd4;
    }
  }

  .content {
    h6 {
      font-family: "Karla";
      font-size: 1.05rem;
      line-height: 1.4;
      letter-spacing: -0.25px;
      opacity: 0.9;
      text-align: center;
      font-weight: normal;
      font-size: 95%;
      margin-bottom: 54px;
      margin-top: 10px;
    }

    h5 {
      text-align: center;
      font-size: 1.05rem;
    }

    h4 {
      margin-bottom: 0.45rem;
    }

    p {
      font-family: "Karla";
      font-size: 1.05rem;
      line-height: 1.4;
      letter-spacing: -0.25px;
      opacity: 0.9;
    }

    img {
      height: 30vw;
      object-fit: contain;
      width: 100%;
      max-width: 560px;
      text-align: center;
      margin: auto;
      display: block;
    }

    h1 {
      opacity: 0.9;
    }

    a {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 1053px) {
    flex-direction: Column;
    padding: 4% 10%;
    .blogpost {
      width: 100%;
      padding-right: 0;
    }
  }
`;

const BlogPost = ({ data, pageContext }) => {
  const {
    title,
    content: { content: richTextDocument },
    image,
    tags,
    category,
    id,
    createdAt
  } = data.contentfulBlogPost;

  const { nextPost, prevPost } = pageContext.blogPosts
    .filter(post => post.category == category)
    .reduce((selectedPosts, post, i, posts) => {
      if (post.id == id) {
        const prevPost = posts[i + 1];
        const nextPost = posts[i - 1];

        return {
          ...selectedPosts,
          prevPost: prevPost
            ? { ...prevPost, path: `/blogpost/${prevPost.slug}` }
            : null,
          nextPost: nextPost
            ? { ...nextPost, path: `/blogpost/${nextPost.slug}` }
            : null
        };
      }
      return selectedPosts;
    }, {});
  console.log("here: ", prevPost, nextPost);
  console.log("current: ", createdAt);

  return (
    <Layout
      headerComponent={header}
      headerContainerExtraClassName="blogpost"
      headerImage={image.file.url}
      heroText={title}
    >
      <SEO title={title} />
      <BodyContentEl>
        <div className="blogpost">
          <span className="date-text">
            {new Date(createdAt).toLocaleDateString("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric"
            })}
          </span>
          <Link to={`/blogposts?category=${category}`}>
            <h5 className="category-pill">{category}</h5>
          </Link>
          <div className="content">
            {parse(documentToHtmlString(JSON.parse(richTextDocument), options))}
          </div>
          {prevPost && (
            <Link className="blog-nav-btn" to={prevPost.path}>
              &#8666;Previous Post
            </Link>
          )}
          {nextPost && (
            <Link className="blog-nav-btn" to={nextPost.path}>
              Next Post&#8667;
            </Link>
          )}
        </div>
        {/* <SideBar /> */}
      </BodyContentEl>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      createdAt
      category
      id
      content {
        content
      }
      image {
        file {
          url
        }
      }
      tags
    }
  }
`;
