import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { graphql, StaticQuery } from "gatsby";
import defaultBackgroundImageUrl from "../images/defaultImg.jpg";
import ViewMoreButton from "./ViewMoreButton";
import BackgroundImage from "gatsby-background-image";

const HeaderEl = styled.header`
  .header-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: ${({ backgroundImageUrl }) => {
      return backgroundImageUrl
        ? `url(${backgroundImageUrl})`
        : `url(${defaultBackgroundImageUrl})`;
    }};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 8%;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;

    /* h3 {
      font-family: QuickBrush, sans-serif;
      font-size: 4rem;
      font-weight: bold;
      text-align: center;
      color: white;
    } */

    nav {
      position: fixed;
      width: 100%;
      top: 0;
      /* background-color: rgba(0, 0, 0, 0.5); */
      background-color: #00a5cbd4;

      color: white;
      box-sizing: border-box;
      z-index: 99;
      transition: all 0.25s ease-out;

      &:hover {
        /* background-color: rgba(183, 43, 177, 0.5); */
        background-color: #00a5cb;
        color: white;

        .header-container .site-title {
          color: white;
        }
      }

      .nav-children-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 30px;

        .site-title {
          font-family: "Karla";
          text-transform: uppercase;
          font-weight: 700;
          font-size: 1.8rem;
          letter-spacing: 0;
          text-align: center;
          margin: 15px 0px;
          padding: 0 18px;
        }

        .hamburger-nav {
          display: none;
          width: 28px;
          height: 28px;
          font-size: 28px;
          margin: 12px;
          justify-content: center;
          align-items: center;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;

          a {
            text-decoration: none;
            color: inherit;
            padding: 0 18px;

            &:first-child {
              padding-left: 0px;
            }

            &:hover {
              color: #8ce9ff;
            }

            /* li.header-text {
              margin: 0;
              text-transform: uppercase;
              font-size: 0.7rem;
              letter-spacing: 0.9px;
              line-height: 38px;
              font-family: Montserrat, sans-serif;
            } */
          }
        }
      }
    }

    &.blogpost {
      height: 60vw;
    }
  }

  /* media queries start here */

  /* 939px is when hamburger comes outs */

  @media screen and (max-width: 939px) {
    .header-container {
      padding-top: 0px;
      padding-bottom: 0px;

      h2 {
        font-size: 2.3rem;
      }

      h3 {
        font-size: 1.1rem;
      }
      nav {
        .nav-children-wrapper {
          padding: 0 12px;

          .site-title {
            font-size: 1.7rem;
          }

          ul {
            display: none;
          }

          .hamburger-nav {
            display: flex;
            justify-content: flex-start;
          }
        }
      }
    }
  }

  /* media query for blog post page starts here */

  @media screen and (max-width: 869px) {
    .header-container {
      &.blogpost {
        h2 {
          font-size: 3rem;
        }
      }
    }
  }
  @media screen and (max-width: 753px) {
    .header-container {
      &.blogpost {
        h2 {
          font-size: 2.6rem;
        }
      }
    }
  }
  @media screen and (max-width: 658px) {
    .header-container {
      &.blogpost {
        h2 {
          font-size: 2.2rem;
        }
      }
    }
  }
  @media screen and (max-width: 587px) {
    .header-container {
      &.blogpost {
        h2 {
          font-size: 1.8rem;
        }
      }
    }
  }

  @media screen and (max-width: 417px) {
    .header-container {
      &.blogpost {
        h2 {
          font-size: 1.4rem;
        }
      }
    }
  }

  @media screen and (max-width: 333px) {
    .header-container {
      &.blogpost {
        h2 {
          font-size: 1.2rem;
        }
      }
    }
  }

  @media screen and (max-width: 599px) {
    .header-container {
      h2 {
        font-size: 2rem;
      }

      h3 {
        font-size: 1rem;
        padding: 0 7px;
      }
    }
  }

  @media screen and (max-width: 428px) {
    .header-container {
      h2 {
        font-size: 1.7rem;
      }

      h3 {
        font-size: 1rem;
      }
      nav .nav-children-wrapper .site-title {
        padding: 0 5px;
      }
    }
  }
`;

const HeroText = styled.div`
  margin-top: 59px;

  top: 0;
  background-color: #ffffff;
  width: 100%;
  padding: 3%;
  margin-bottom: 0;
  .hero-header {
    font-family: Montserrat, sans-serif;
    font-size: 1.7rem;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    color: #6f6d6d;
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 939px) {
    margin-top: 53px;
    padding: 4% 3% 3%;

    .hero-header {
      font-size: 1.4rem;
    }
  }

  @media screen and (max-width: 480px) {
    margin-top: 52px;
    .hero-header {
      font-size: 1.2rem;
    }
  }
`;

const Header = ({
  siteTitle,
  backgroundImageUrl,
  heroText,
  heroSubText,
  extraClassName,
  onClickHamburger
}) => (
  <React.Fragment>
    <HeroText className="hero-text">
      {heroText ? (
        <React.Fragment>
          <h2 className="hero-header">{heroText}</h2>
          <h3 className="hero-header">{heroSubText}</h3>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h2 className="hero-header">LOOK FEEL HERddddE</h2>
          <h3 className="hero-header">your best life!</h3>
          {/* <ViewMoreButton title="start here" /> */}
        </React.Fragment>
      )}
    </HeroText>

    <HeaderEl backgroundImageUrl={backgroundImageUrl}>
      <BackgroundImage
        Tag="div"
        className={"header-container" + " " + extraClassName}
        fluid={backgroundImageUrl}
      >
        <nav>
          <div className="nav-children-wrapper">
            <h1 className="site-title">{siteTitle}</h1>
            <ul>
              <Link to="">
                <li className="nav-text">home</li>
              </Link>

              <Link to="/about/">
                <li className="nav-text">about</li>
              </Link>
              <Link to="/blogposts/">
                <li className="nav-text">categories</li>
              </Link>
              <Link to="/workouts/">
                <li className="nav-text">Workouts</li>
              </Link>
              <Link to="/youtube/">
                <li className="nav-text">youtube</li>
              </Link>

              <Link to="/contact">
                <li className="nav-text">contact</li>
              </Link>
            </ul>
            <div onClick={onClickHamburger} className="hamburger-nav">
              <FaBars />
            </div>
          </div>
        </nav>
      </BackgroundImage>
    </HeaderEl>
  </React.Fragment>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``,
  extraClassName: ``
};

export default Header;
