import React from "react";
import styled from "styled-components";
import ViewMoreButton from "../components/ViewMoreButton";
import YoutubeFrame from "./common/YoutubeFrame";

const AboutYoutubeSectionEl = styled.div`
  /* display: flex; */
  /* padding-bottom: 3%; */

  .section-container {
    background: #ffffff;
    width: 100%;
    text-align: center;
    padding: 6% 10% 3%;

    .h4-text {
      text-transform: uppercase;
      margin-bottom: 1.8rem;
      letter-spacing: 3px;
    }

    .video-container {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 50px;
    }
  }

  @media only screen and (max-width: 923px) {
    .section-container {
      padding: 9% 3%;

      .video-container {
        width: 100%;
      }
      .text-container {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 503px) {
    .section-container {
      padding: 9% 3%;
    }
  }
`;

const AboutYoutubeSection = () => {
  return (
    <AboutYoutubeSectionEl>
      <div className="section-container">
        <h4 className="h4-text">Youtube</h4>

        <div className="video-container">
          <YoutubeFrame width="50%" />
          <YoutubeFrame width="50%" />
        </div>

        <ViewMoreButton
          title="Subscribe on youtube!"
          externalLink="https://www.youtube.com/dreyahfit"
        />
      </div>
    </AboutYoutubeSectionEl>
  );
};

export default AboutYoutubeSection;
