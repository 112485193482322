import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import nutritionImg from "../images/category-section/nutrition.jpg";
import fitnessImg from "../images/category-section/fitness.jpg";
import lifestyleImg from "../images/category-section/lifestyle.jpg";
import workoutImg from "../images/category-section/workouts.jpg";

import ViewMoreButton from "./ViewMoreButton";

const CategoryPreviewEl = styled.div`
  .section-container {
    text-align: center;
    padding: 2% 8%;

    .h4-text {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 600;
      margin-bottom: 1.5rem;
    }

    .category-links-wrapper {
      /* padding: 5% 0% 0%; */
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      transition: all 0.3s ease-in-out;

      .category-link-wrapper {
        /* width: 212px; */
        /* height: 315px; */
        min-height: 300px;
        padding: 2%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: 0;
        margin-bottom: 40px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        text-decoration: none;

        &:last-child {
          margin-right: 0px;
        }

        .box {
          background: #fffffff7;
          width: 160px;
          padding: 10px;
          position: relative;
          transition: all 0.3s ease-in-out;

          .h5-text {
            font-size: 1rem;
            color: #0e0e0e;
            text-transform: uppercase;
            border: 0.5px solid #0e0e0e3d;
            padding: 10px;
            text-align: center;
            margin: 0;
            transition: all 0.3s ease-in-out;
          }
        }

        &:hover {
          top: -10px;

          .box {
            background: #00b7d7;
            .h5-text {
              color: #ffffff;
              border-color: #ffffff;
            }
          }
        }
      }
    }

    @media screen and (max-width: 923px) {
      padding: 4% 2% 0%;
      .category-links-wrapper .category-link-wrapper {
        min-height: 245px;
      }
    }
  }
`;

const CategoryPreview = ({ siteTitle }) => {
  return (
    <CategoryPreviewEl>
      <div className="section-container">
        <h4 className="h4-text">Take Your pick</h4>
        <div className="category-links-wrapper">
          <CategoryPreviewItem
            link={"/blogposts?category=fitness"}
            backgroundImage={fitnessImg}
            text="fitness"
          />
          <CategoryPreviewItem
            backgroundImage={nutritionImg}
            text="nutrition"
            link={"/blogposts?category=nutrition"}
          />
          <CategoryPreviewItem
            backgroundImage={lifestyleImg}
            text="lifestyle"
            link={"/blogposts?category=lifestyle"}
          />
          <CategoryPreviewItem
            backgroundImage={workoutImg}
            text="Workouts"
            link={"/workouts/"}
          />
        </div>
      </div>
    </CategoryPreviewEl>
  );
};

const CategoryPreviewItem = ({ text, backgroundImage, link }) => (
  <Link
    to={link}
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover"
    }}
    className="category-link-wrapper"
  >
    <div className="box">
      <h5 className="h5-text">{text}</h5>
    </div>
    {/* <ViewMoreButton title={buttonText} link={buttonLink} /> */}
  </Link>
);

export default CategoryPreview;
