import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
// import defaultBackgroundImageUrl from "../images/dreyahgymImg.jpg";
import defaultBackgroundImageUrl from "../images/header_test.png";
import ViewMoreButton from "./ViewMoreButton";

const HeaderEl = styled.header`
  .header-container {
    display: flex;
    flex-direction: column;
    /* height: 39vh; */
    height: ${({ heroText }) => (heroText ? "35vh" : "max-content")};

    background-color: #62c3d4;

    background-image: ${({ backgroundImageUrl }) => {
      return backgroundImageUrl
        ? `url(${backgroundImageUrl})`
        : `url(${defaultBackgroundImageUrl})`;
    }};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 8%;
    margin-top: 51px;
    margin-bottom: 32px;
    align-items: center;
    justify-content: center;

    h2 {
      font-family: "Karla";
      font-size: 2.5rem;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: white;
      margin-bottom: 0.7rem;
    }

    .blog-post {
    }

    h3 {
      font-family: "Karla";
      font-size: 1.2rem;
      font-weight: 100;
      text-align: center;
      color: white;
      text-align: center;
      max-width: 515px;
    }

    nav {
      position: fixed;
      width: 100%;
      top: 0;
      /* background-color: #00a5cbd4; */
      background-color: ${({ heroText }) => (heroText ? "#fff" : "#00a5cbd4")};
      box-sizing: border-box;
      z-index: 99;
      transition: all 0.25s ease-out;

      /* &:hover {
        background-color: #00a5cb;
        color: white;

        .header-container .site-title {
          color: white;
        }
      } */

      .nav-children-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 30px;

        .site-title {
          font-family: "Karla";
          text-transform: uppercase;
          font-weight: 700;
          font-size: 1.4rem;
          letter-spacing: 0;
          text-align: center;
          margin: 12px 0px;
          padding: 0 18px;
        }

        .hamburger-nav {
          display: none;
          width: 28px;
          height: 28px;
          font-size: 28px;
          margin: 12px;
          justify-content: center;
          align-items: center;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;

          a {
            text-decoration: none;
            color: inherit;
            padding: 0 18px;

            &:first-child {
              padding-left: 0px;
            }

            &:hover {
              color: #8ce9ff;
            }
          }
        }
      }
    }
  }

  /* media queries start here */

  /* 939px is when hamburger comes outs */

  @media screen and (max-width: 1177px) {
    .header-container {
      padding-top: 0px;
      padding-bottom: 0px;

      h2 {
        font-size: 2.3rem;
      }

      h3 {
        font-size: 1.1rem;
      }
      nav {
        .nav-children-wrapper {
          padding: 0 12px;

          .site-title {
            font-size: 1.7rem;
          }

          ul {
            display: none;
          }

          .hamburger-nav {
            display: flex;
            justify-content: flex-start;
          }
        }
      }
    }
  }

  /* media query for blog post page starts here */

  @media screen and (max-width: 869px) {
    .header-container {
      &.blogpost {
        h2 {
          font-size: 3rem;
        }
      }
    }
  }
  @media screen and (max-width: 753px) {
    .header-container {
      &.blogpost {
        h2 {
          font-size: 2.6rem;
        }
      }
    }
  }
  @media screen and (max-width: 658px) {
    .header-container {
      &.blogpost {
        h2 {
          font-size: 2.2rem;
        }
      }
    }
  }
  @media screen and (max-width: 587px) {
    .header-container {
      &.blogpost {
        h2 {
          font-size: 1.8rem;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .header-container {
      margin-bottom: 10px;
      &.blogpost {
        margin-top: 59px;
      }
    }
  }

  @media screen and (max-width: 417px) {
    .header-container {
      &.blogpost {
        h2 {
          font-size: 1.4rem;
        }
      }
    }
  }

  @media screen and (max-width: 333px) {
    .header-container {
      &.blogpost {
        h2 {
          font-size: 1.2rem;
        }
      }
    }
  }

  @media screen and (max-width: 599px) {
    .header-container {
      h2 {
        font-size: 2rem;
      }

      h3 {
        font-size: 1rem;
        padding: 0 7px;
      }
    }
  }

  @media screen and (max-width: 428px) {
    .header-container {
      h2 {
        font-size: 1.7rem;
      }

      h3 {
        font-size: 1rem;
      }
      nav .nav-children-wrapper .site-title {
        padding: 0 5px;
      }
    }
  }
`;

const Header = ({
  siteTitle,
  backgroundImageUrl,
  heroText,
  heroSubText,
  extraClassName,
  onClickHamburger
}) => (
  <HeaderEl heroText={heroText} backgroundImageUrl={backgroundImageUrl}>
    <div className={"header-container" + " " + extraClassName}>
      <div className="hero-text">
        {heroText ? (
          <React.Fragment>
            <h2 className="hero-header">{heroText}</h2>
            <h3 className="hero-header">{heroSubText}</h3>
          </React.Fragment>
        ) : null}
      </div>

      <nav>
        <div className="nav-children-wrapper">
          <h1 className="site-title">{siteTitle}</h1>
          <ul>
            <Link to="">
              <li className="nav-text">home</li>
            </Link>
            <Link to="/blogposts?category=fitness">
              <li className="nav-text">Fitness</li>
            </Link>

            <Link to="/blogposts?category=lifestyle">
              <li className="nav-text">Lifestyle</li>
            </Link>

            <Link to="/blogposts?category=nutrition">
              <li className="nav-text">Nutrition</li>
            </Link>
            {/* <Link to="/workout/">
              <li className="nav-text">Workout Of The Week</li>
            </Link> */}
            <Link to="/workouts/">
              <li className="nav-text">Workouts</li>
            </Link>

            <Link to="/about/">
              <li className="nav-text">about</li>
            </Link>

            <Link to="/contact/">
              <li className="nav-text">contact</li>
            </Link>
          </ul>
          <div onClick={onClickHamburger} className="hamburger-nav">
            <FaBars />
          </div>
        </div>
      </nav>
    </div>
  </HeaderEl>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``,
  extraClassName: ``
};

export default Header;
