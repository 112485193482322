import React from "react";
import styled from "styled-components";
import {
  FaBars,
  FaInstagram,
  FaTwitter,
  FaYoutube,
  FaFacebook,
  FaPodcast,
  FaPinterest
} from "react-icons/fa";

const SocialLinksEl = styled.header`
  .social-links {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    .social-link-text-wrapper {
      /* width: 25%; */
      display: flex;
      justify-content: center;
      padding-right: 10px;
      flex-direction: column;
      align-items: center;

      .paragraph-text {
        color: white;
        text-align: center;
        font-size: 15px;
      }

      .social-link {
        color: #0e0e0e;
        margin-bottom: 8px;
        padding: 23px;
        /* border: 1px solid #0e0e0e; */

        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 72px;
        height: 72px;

        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          background: #0e0e0e;
          color: #ffffff;
        }
      }
    }
  }

  @media screen and (max-width: 375px) {
    .social-links .social-link-text-wrapper {
      padding-right: 4px;
    }
  }
`;

const SocialLinks = () => {
  return (
    <SocialLinksEl>
      <div className="social-links">
        <div className="social-link-text-wrapper">
          <a
            className="social-link"
            target="_blank"
            href="https://www.instagram.com/everyfitgirl/"
            rel="noopener noreferrer"
          >
            <FaInstagram size={39} />
          </a>
          {/* <p className="paragraph-text">INSTAGRAM, my fav place!</p> */}
        </div>

        <div className="social-link-text-wrapper">
          <a
            className="social-link"
            target="_blank"
            href="https://www.facebook.com/everyfitgirl/"
            rel="noopener noreferrer"
          >
            <FaFacebook size={39} />
          </a>
          {/* <p className="paragraph-text">FACEBOOK, say hi!</p> */}
        </div>
        <div className="social-link-text-wrapper">
          <a
            className="social-link"
            target="_blank"
            href="https://www.youtube.com/dreyahcraig"
            rel="noopener noreferrer"
          >
            <FaYoutube size={39} />
          </a>
        </div>

        <div className="social-link-text-wrapper">
          <a
            className="social-link"
            target="_blank"
            href="https://www.pinterest.com/everyfitgirl/"
            rel="noopener noreferrer"
          >
            <FaPinterest size={38} />
          </a>
        </div>
      </div>
    </SocialLinksEl>
  );
};

export default SocialLinks;
