import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

class InstagramProvider extends React.Component {
  render() {
    if (typeof this.props.children !== "function") {
      throw new Error(
        "Please pass in a render prop as a child function to the InstagramProvider component"
      );
    } else {
      return (
        <StaticQuery
          query={graphql`
            query {
              allInstaNode {
                edges {
                  node {
                    id
                    likes
                    comments
                    mediaType
                    preview
                    original
                    timestamp
                    caption
                    localFile {
                      childImageSharp {
                        fixed(width: 300, height: 300) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          render={({ allInstaNode: { edges } }) => {
            const images = edges
              .sort((a, b) => b.node.timestamp - a.node.timestamp)
              // we either have a limit or we send in undefined and return all
              .slice(0, this.props.limit || undefined)
              .map(({ node }) => node);

            return this.props.children(images);
          }}
        />
      );
    }
  }
}

InstagramProvider.propTypes = {
  children: PropTypes.func.isRequired,
  limit: PropTypes.number
};

export default InstagramProvider;
