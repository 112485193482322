import React from "react";
import styled from "styled-components";
import dreyahFace from "../images/dreyah-face.jpg";
import ViewMoreButton from "../components/ViewMoreButton";

const AboutSectionEl = styled.div`
  display: flex;
  padding: 0% 7% 4%;
  .image-container {
    width: 40%;
    background-image: url(${dreyahFace});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 57% 57%;
  }

  .text-container {
    width: 60%;
    background-color: #f2a69b45;
    text-align: initial;
    padding: 5% 5% 3%;

    .h4-text {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 1.2rem;
      font-weight: 700;
      padding-bottom: 1.5rem;
      margin-bottom: 0;
    }

    .paragraph-wrapper {
      margin-bottom: 3rem;
    }
    .viewmore-button-wrapper {
      display: flex;
      justify-content: flex-start;
    }
  }

  @media only screen and (max-width: 923px) {
    flex-wrap: wrap;

    .image-container {
      width: 100%;
      height: 60vh;
    }
    .text-container {
      text-align: center;
      width: 100%;
      padding: 10% 5% 12% 5%;

      .viewmore-button-wrapper {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

const AboutSection = () => {
  return (
    <AboutSectionEl>
      <div className="image-container" />
      <div className="text-container">
        <h4 className="h4-text">hey hi hello</h4>
        <div className="paragraph-wrapper">
          <p className="paragraph-text">Welcome to Every Fit Girl!</p>
          <p className="paragraph-text">
            Here you'll find the best resources you need to live an authentic,
            fit and healthy lifestyle, while having as much fun as possible.
          </p>
          {/* <p className="paragraph-text">
             I'm Dreyah, a fitness entrepreneur, trainer
            and dance instructor and I created Dreyah Fit to provide women with
            the best resources they need to live an authentic, fit and healthy
            lifestyle, while having as much fun as possible.
            I like to think of myself as the girl next door who never wants to
            turn down a meal just to stay in shape. I’m all about independence
            and finding a healthy and happy way to feel incredible in your own
            skin.
          </p> */}

          <p className="paragraph-text">
            Here you'll find content that helps you become a better and
            healthier version of yourself while navigating through life, because
            your journey isn’t about perfection, it’s about moving forward,
            sometimes backward and making the most out of every moment in
            between.
          </p>

          <p className="paragraph-text">
            Welcome to the fam! Welcome to a space where Every Girl is welcome!
          </p>
          <p className="paragraph-text">XO,</p>

          <p className="paragraph-text">Dreyah :)</p>

          {/* <p className="paragraph-text">
            We hope to inspire wellness, realness, and fearless motivation in
            your daily life. I want you to make yourself proud, and I am here to
            remind you of your own power.
          </p> */}
        </div>
        <div className="viewmore-button-wrapper">
          <ViewMoreButton title="learn more" link="/about" />
        </div>
      </div>
    </AboutSectionEl>
  );
};

export default AboutSection;
