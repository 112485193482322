import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Header from "./header";
import "./layout.css";
import CategoryPreview from "../components/CategoryPreview";
import Subscription1 from "./Subscription1";
import InstagramImages from "../components/InstagramImages";
import SideDrawer from "./common/SideDrawer";
import Backdrop from "./common/Backdrop";
import HeaderWrapper from "./HeaderWrapper";
import AboutYoutubeSection from "../components/AboutYoutubeSection";

const Layout = ({
  children,
  headerImage,
  heroText,
  heroSubText,
  headerContainerExtraClassName,
  headerComponent: ProvidedHeader
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <HeaderWrapper>
            {(showSidebar, backdropClickHandler, drawerToggleClickHandler) => {
              let backdrop;

              if (showSidebar) {
                backdrop = <Backdrop click={backdropClickHandler} />;
              }

              return (
                <>
                  <SideDrawer show={showSidebar} />
                  {backdrop}
                  {ProvidedHeader ? (
                    <ProvidedHeader
                      extraClassName={headerContainerExtraClassName}
                      heroText={heroText}
                      heroSubText={heroSubText}
                      backgroundImageUrl={headerImage}
                      siteTitle={data.site.siteMetadata.title}
                      onClickHamburger={drawerToggleClickHandler}
                    />
                  ) : (
                    <Header
                      extraClassName={headerContainerExtraClassName}
                      heroText={heroText}
                      heroSubText={heroSubText}
                      backgroundImageUrl={headerImage}
                      siteTitle={data.site.siteMetadata.title}
                      onClickHamburger={drawerToggleClickHandler}
                    />
                  )}
                </>
              );
            }}
          </HeaderWrapper>

          <main>{children}</main>
          <footer>
            <CategoryPreview />
            <Subscription1 siteTitle={data.site.siteMetadata.title} />
          </footer>
        </>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
