import React from "react";
import styled from "styled-components";
import { FaHeart, FaComment } from "react-icons/fa";
import ImagesProvider from "./common/InstagramProvider";

const ImagesWrapperEl = styled.div`
  display: flex;
  box-sizing: border-box;

  .insta-image {
    width: 16.68%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    position: relative;
    line-height: 0;
    transition: all 0.2s ease-out;

    img {
      width: 100% !important;
      height: 290px;
      margin: 0;
      opacity: 0;
    }

    .insta-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      background: rgba(255, 255, 255, 1);
      display: none;
      z-index: 95;
      transition: all 0.2s ease-out;

      .insta-meta {
        font-size: 11px;
        color: #050505;

        &:last-child {
          margin-left: 10px;
        }
      }

      &.insta-overlay-shown {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.6);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .insta-image img {
      height: 220px;
    }
  }

  @media screen and (max-width: 842px) {
    flex-wrap: wrap;

    .insta-image {
      width: 33.33%;
      img {
        height: 280px;
      }
    }
    @media screen and (max-width: 425px) {
      .insta-image {
        img {
          height: 190px;
        }
      }
    }
  }
`;

class InstagramImages extends React.Component {
  state = {
    hovered: ""
  };

  makeHovered = id => {
    this.setState({ hovered: id });
  };

  removeHovered = () => {
    this.setState({ hovered: "" });
  };

  render() {
    const { block, limit, instaImageStyle, imagesWrapperStyle } = this.props;

    return (
      <ImagesProvider limit={limit}>
        {images => {
          return (
            <ImagesWrapperEl style={imagesWrapperStyle} block={block}>
              {images.map(({ id, original, localFile, likes, comments }) => {
                return (
                  <a
                    onMouseEnter={() => this.makeHovered(id)}
                    onMouseLeave={() => this.removeHovered(id)}
                    href={`https://www.instagram.com/p/${id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="insta-image"
                    key={id}
                    style={{
                      backgroundImage: `url(${original})`,
                      ...instaImageStyle
                    }}
                  >
                    <img
                      alt="instagram content"
                      src={localFile.childImageSharp.fixed.base64}
                    />
                    {
                      <div
                        className={`insta-overlay ${this.state.hovered === id &&
                          "insta-overlay-shown"}`}
                      >
                        <span className="insta-meta">
                          <FaHeart /> {likes}
                        </span>
                        <span className="insta-meta">
                          <FaComment /> {comments}
                        </span>
                      </div>
                    }
                  </a>
                );
              })}
            </ImagesWrapperEl>
          );
        }}
      </ImagesProvider>
    );
  }
}

export default InstagramImages;
