import React from "react";
import styled from "styled-components";

const SectionDividerEl = styled.div`
  text-align: center;
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight};

  .border-line {
    border-top: 1px solid #ded6d6;
    /* margin-top: 25px; */

    .section-divider-title {
      font-family: "karla", open sans;
      font-weight: 600;
      font-size: 1.2rem;
      color: #333333;
      text-transform: uppercase;
      letter-spacing: 1px;
      width: max-content;
      background: white;
      margin: auto;
      position: relative;
      font-size: ${({ small }) => {
        return small ? "1rem" : "1.2rem";
      }};
      top: ${({ small }) => {
        return small ? "-13px" : "-15px";
      }};
      padding: ${({ small }) => {
        return small ? "2px 10px" : "2px 21px";
      }};
    }
  }

  @media only screen and (max-width: 923px) {
    /* padding: 0; */
  }
`;

const SectionDivider = ({ title, small, paddingLeft, paddingRight }) => {
  return (
    <SectionDividerEl
      small={small}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
    >
      <div className="border-line">
        <h2 className="section-divider-title">{title}</h2>
      </div>
    </SectionDividerEl>
  );
};

export default SectionDivider;
